"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "../img/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// import stylu
import "./../sass/index.scss";

// tiny slider

import { tns } from "tiny-slider/src/tiny-slider";

//menu
window.onload = function () {
	//MENU OPEN
	var opener = document.getElementById("ccollapseToggle");
	if (opener) {
		opener.onclick = function () {
			console.log("he you");
			menuManipulate();
		};
	}

	var tnsTest = document.getElementById("HPCarousel");
	if (tnsTest) {
		var slider = tns({
			container: ".hp-slider",
			items: 1,
			center: true,
			mouseDrag: true,
			fixedWidth: false,
			slideBy: "1",
			autoplay: true,
			responsive: {
				"650": {
					fixedWidth: 650,
				},
			},
		});
	}
};

function menuManipulate() {
	const openQ = document.querySelector("#ccollapse");
	if (openQ.classList.contains("open")) {
		//console.log("otevřeno");
		openQ.classList.remove("open");
	} else {
		//console.log("zavřeno");
		openQ.classList.add("open");
	}
}

function menuClose() {
	const openQ = document.querySelector("#ccollapse");
	openQ.classList.remove("open");
}
